@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fade-in-top {
    0% {
        transform: translateY(-50px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes fade-in-right {
    0% {
        transform: translateX(50px);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes fade-in-bottom {
    0% {
        transform: translateY(50px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes fade-in-left {
    0% {
        transform: translateX(-50px);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slide-in-top {
    0% {
        transform: translateY(-1000px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slide-in-bottom {
    0% {
        transform: translateY(1000px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slide-in-right {
    0% {
        transform: translateX(1000px);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slide-in-left {
    0% {
        transform: translateX(-1000px);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slide-in-fwd-center {
    0% {
        transform: translateZ(-1400px);
        opacity: 0;
    }
    100% {
        transform: translateZ(0);
        opacity: 1;
    }
}

@keyframes slide-in-fwd-top {
    0% {
        transform: translateZ(-1400px) translateY(-800px);
        opacity: 0;
    }
    100% {
        transform: translateZ(0) translateY(0);
        opacity: 1;
    }
}

@keyframes slide-in-fwd-right {
    0% {
        transform: translateZ(-1400px) translateX(1000px);
        opacity: 0;
    }
    100% {
        transform: translateZ(0) translateX(0);
        opacity: 1;
    }
}

@keyframes slide-in-fwd-left {
    0% {
        transform: translateZ(-1400px) translateX(-1000px);
        opacity: 0;
    }
    100% {
        transform: translateZ(0) translateX(0);
        opacity: 1;
    }
}

@keyframes slide-in-fwd-bottom {
    0% {
        transform: translateZ(-1400px) translateY(800px);
        opacity: 0;
    }
    100% {
        transform: translateZ(0) translateY(0);
        opacity: 1;
    }
}

@keyframes swing-in-top-fwd {
    0% {
        transform: rotateX(-100deg);
        transform-origin: top;
        opacity: 0;
    }
    100% {
        transform: rotateX(0deg);
        transform-origin: top;
        opacity: 1;
    }
}

@keyframes swing-in-top-bck {
    0% {
        transform: rotateX(70deg);
        transform-origin: top;
        opacity: 0;
    }
    100% {
        transform: rotateX(0deg);
        transform-origin: top;
        opacity: 1;
    }
}

@keyframes swing-in-right-bck {
    0% {
        transform: rotateY(70deg);
        transform-origin: right;
        opacity: 0;
    }
    100% {
        transform: rotateY(0);
        transform-origin: right;
        opacity: 1;
    }
}

@keyframes swing-in-bottom-bck {
    0% {
        transform: rotateX(-70deg);
        transform-origin: bottom;
        opacity: 0;
    }
    100% {
        transform: rotateX(0);
        transform-origin: bottom;
        opacity: 1;
    }
}

@keyframes swing-in-left-bck {
    0% {
        transform: rotateY(-70deg);
        transform-origin: left;
        opacity: 0;
    }
    100% {
        transform: rotateY(0);
        transform-origin: left;
        opacity: 1;
    }
}

@keyframes flicker-in-1 {
    0% {
        opacity: 0;
    }
    10% {
        opacity: 0;
    }
    10.1% {
        opacity: 1;
    }
    10.2% {
        opacity: 0;
    }
    20% {
        opacity: 0;
    }
    20.1% {
        opacity: 1;
    }
    20.6% {
        opacity: 0;
    }
    30% {
        opacity: 0;
    }
    30.1% {
        opacity: 1;
    }
    30.5% {
        opacity: 1;
    }
    30.6% {
        opacity: 0;
    }
    45% {
        opacity: 0;
    }
    45.1% {
        opacity: 1;
    }
    50% {
        opacity: 1;
    }
    55% {
        opacity: 1;
    }
    55.1% {
        opacity: 0;
    }
    57% {
        opacity: 0;
    }
    57.1% {
        opacity: 1;
    }
    60% {
        opacity: 1;
    }
    60.1% {
        opacity: 0;
    }
    65% {
        opacity: 0;
    }
    65.1% {
        opacity: 1;
    }
    75% {
        opacity: 1;
    }
    75.1% {
        opacity: 0;
    }
    77% {
        opacity: 0;
    }
    77.1% {
        opacity: 1;
    }
    85% {
        opacity: 1;
    }
    85.1% {
        opacity: 0;
    }
    86% {
        opacity: 0;
    }
    86.1% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}

@keyframes roll-in-blurred-left {
    0% {
        transform: translateX(-1000px) rotate(-720deg);
        filter: blur(50px);
        opacity: 0;
    }
    100% {
        transform: translateX(0) rotate(0deg);
        filter: blur(0);
        opacity: 1;
    }
}

@keyframes roll-in-blurred-top {
    0% {
        transform: translateY(-800px) rotate(-720deg);
        filter: blur(50px);
        opacity: 0;
    }
    100% {
        transform: translateY(0) rotate(0deg);
        filter: blur(0);
        opacity: 1;
    }
}

@keyframes roll-in-blurred-bottom {
    0% {
        transform: translateY(800px) rotate(720deg);
        filter: blur(50px);
        opacity: 0;
    }
    100% {
        transform: translateY(0) rotate(0deg);
        opacity: 1;
    }
}

@keyframes roll-in-blurred-right {
    0% {
        transform: translateX(1000px) rotate(720deg);
        filter: blur(50px);
        opacity: 0;
    }
    100% {
        transform: translateX(0) rotate(0deg);
        filter: blur(0);
        opacity: 1;
    }
}

@keyframes bounce-in-top {
    0% {
        transform: translateY(-500px);
        animation-timing-function: ease-in;
        opacity: 0;
    }
    38% {
        transform: translateY(0);
        animation-timing-function: ease-out;
        opacity: 1;
    }
    55% {
        transform: translateY(-65px);
        animation-timing-function: ease-in;
    }
    72% {
        transform: translateY(0);
        animation-timing-function: ease-out;
    }
    81% {
        transform: translateY(-28px);
        animation-timing-function: ease-in;
    }
    90% {
        transform: translateY(0);
        animation-timing-function: ease-out;
    }
    95% {
        transform: translateY(-8px);
        animation-timing-function: ease-in;
    }
    100% {
        transform: translateY(0);
        animation-timing-function: ease-out;
    }
}

@keyframes bounce-in-right {
    0% {
        transform: translateX(600px);
        animation-timing-function: ease-in;
        opacity: 0;
    }
    38% {
        transform: translateX(0);
        animation-timing-function: ease-out;
        opacity: 1;
    }
    55% {
        transform: translateX(68px);
        animation-timing-function: ease-in;
    }
    72% {
        transform: translateX(0);
        animation-timing-function: ease-out;
    }
    81% {
        transform: translateX(32px);
        animation-timing-function: ease-in;
    }
    90% {
        transform: translateX(0);
        animation-timing-function: ease-out;
    }
    95% {
        transform: translateX(8px);
        animation-timing-function: ease-in;
    }
    100% {
        transform: translateX(0);
        animation-timing-function: ease-out;
    }
}

@keyframes bounce-in-bottom {
    0% {
        transform: translateY(500px);
        animation-timing-function: ease-in;
        opacity: 0;
    }
    38% {
        transform: translateY(0);
        animation-timing-function: ease-out;
        opacity: 1;
    }
    55% {
        transform: translateY(65px);
        animation-timing-function: ease-in;
    }
    72% {
        transform: translateY(0);
        animation-timing-function: ease-out;
    }
    81% {
        transform: translateY(28px);
        animation-timing-function: ease-in;
    }
    90% {
        transform: translateY(0);
        animation-timing-function: ease-out;
    }
    95% {
        transform: translateY(8px);
        animation-timing-function: ease-in;
    }
    100% {
        transform: translateY(0);
        animation-timing-function: ease-out;
    }
}

@keyframes bounce-in-left {
    0% {
        transform: translateX(-600px);
        animation-timing-function: ease-in;
        opacity: 0;
    }
    38% {
        transform: translateX(0);
        animation-timing-function: ease-out;
        opacity: 1;
    }
    55% {
        transform: translateX(-68px);
        animation-timing-function: ease-in;
    }
    72% {
        transform: translateX(0);
        animation-timing-function: ease-out;
    }
    81% {
        transform: translateX(-28px);
        animation-timing-function: ease-in;
    }
    90% {
        transform: translateX(0);
        animation-timing-function: ease-out;
    }
    95% {
        transform: translateX(-8px);
        animation-timing-function: ease-in;
    }
    100% {
        transform: translateX(0);
        animation-timing-function: ease-out;
    }
}

@keyframes scale-in-center {
    0% {
        transform: scale(0);
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes scale-in-top {
    0% {
        transform: scale(0);
        transform-origin: 50% 0;
        opacity: 1;
    }
    100% {
        transform: scale(1);
        transform-origin: 50% 0;
        opacity: 1;
    }
}

@keyframes scale-in-right {
    0% {
        transform: scale(0);
        transform-origin: 100% 50%;
        opacity: 1;
    }
    100% {
        transform: scale(1);
        transform-origin: 100% 50%;
        opacity: 1;
    }
}

@keyframes scale-in-bottom {
    0% {
        transform: scale(0);
        transform-origin: 50% 100%;
        opacity: 1;
    }
    100% {
        transform: scale(1);
        transform-origin: 50% 100%;
        opacity: 1;
    }
}

@keyframes scale-in-left {
    0% {
        transform: scale(0);
        transform-origin: 0 50%;
        opacity: 1;
    }
    100% {
        transform: scale(1);
        transform-origin: 0 50%;
        opacity: 1;
    }
}

@keyframes slide-in-bck-center {
    0% {
        transform: translateZ(600px);
        opacity: 0;
    }
    100% {
        transform: translateZ(0);
        opacity: 1;
    }
}

@keyframes slide-in-bck-top {
    0% {
        transform: translateZ(700px) translateY(-300px);
        opacity: 0;
    }
    100% {
        transform: translateZ(0) translateY(0);
        opacity: 1;
    }
}

@keyframes slide-in-bck-right {
    0% {
        transform: translateZ(700px) translateX(400px);
        opacity: 0;
    }
    100% {
        transform: translateZ(0) translateX(0);
        opacity: 1;
    }
}

@keyframes slide-in-bck-bottom {
    0% {
        transform: translateZ(700px) translateY(300px);
        opacity: 0;
    }
    100% {
        transform: translateZ(0) translateY(0);
        opacity: 1;
    }
}

@keyframes slide-in-bck-left {
    0% {
        transform: translateZ(700px) translateX(-400px);
        opacity: 0;
    }
    100% {
        transform: translateZ(0) translateX(0);
        opacity: 1;
    }
}
