body {
    --fz-12: 12px;
    --fz-13: 13px;
    --fz-14: 14px;
    --fz-16: 16px;
    --fz-18: 18px;
    --fz-20: 20px;
    --fz-24: 24px;
    --fz-28: 28px;
}
