@import "bootstrap/dist/css/bootstrap.min.css";
@import "quill/dist/quill.core.css";
@import "quill/dist/quill.snow.css";
@import "assets/css/font";
@import "src/assets/css/mixin-style.scss";
@font-face {
    font-family: "LineAwesome";
    src: url("/src/assets/fonts/line-awesome/line-awesome.eot?");
    src: url("/src/assets/fonts/line-awesome/line-awesome.eot?#iefix") format("embedded-opentype"),
        url("/src/assets/fonts/line-awesome/line-awesome.woff2") format("woff2"),
        url("/src/assets/fonts/line-awesome/line-awesome.woff") format("woff"),
        url("/src/assets/fonts/line-awesome/line-awesome.ttf") format("truetype"),
        url("/src/assets/fonts/line-awesome/line-awesome.svg#fa") format("svg");
    font-weight: normal;
    font-style: normal;
}
@import "src/assets/css/animation.scss";
* {
    box-sizing: content-box;
}

:root {
    --icon-safe: url("/assets/icon/account-security-type/success.png");
    --icon-normal: url("/assets/icon/account-security-type/warning.png");
}

html {
    margin: 0;
    padding: 0;
    min-height: 100vh;
}

body {
    font-size: 12px/1.5;
    font-family: Microsoft Yahei, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, PingFang SC, Helvetica Neue,
        Noto Sans, Noto Sans CJK SC, Arial, Hiragino Sans GB, sans-serif;
    // font: 12px/1.5 tahoma,arial,'Hiragino Sans GB','\5b8b\4f53',sans-serif;
}
body.no-scrollbar::-webkit-scrollbar {
    width: 0 !important;
}
body.no-scrollbar {
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
}

.cursor-pointer {
    cursor: pointer;
}

.dropdown-item:focus,
.dropdown-item:hover {
    background-color: transparent;
}

.row > * {
    padding-right: calc(var(--bs-gutter-x) * 0.3);
    padding-left: calc(var(--bs-gutter-x) * 0.3);
    margin-top: var(--bs-gutter-y);
    position: relative;
    box-sizing: border-box;
}

.input-group-prepend {
    .input-group-text {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
}

.form-group {
    label {
        padding-bottom: 0.5rem;
    }
    select.form-control {
        appearance: auto;
    }
}

.am-modal-transparent {
    width: 380px;
    max-width: 88vw;
    .am-modal-header {
        .am-modal-title {
            margin-top: 1rem;
            font-weight: bolder;
        }
    }
    .am-modal-body {
        .am-modal-alert-content {
            min-height: 106px;
            box-sizing: border-box;
        }
    }
}

@media screen and (max-width: 568px) {
    .pc-version {
        display: none !important;
    }
    .mobile-fixed-top {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
    }
    .am-tabs-default-bar-top .am-tabs-default-bar-tab {
        font-size: 13px;
    }
}

@media screen and (min-width: 569px) {
    .app-version {
        display: none !important;
    }
}
.card-fz-color {
    color: var(--card-fz-color);
}

.p-2 {
    padding: 10px;
}

.p-15 {
    padding: 15px;
}

.m-15 {
    margin: 15px;
}

.card-bg-color {
    background-color: var(--color-ff);
}

.border-radius {
    border-radius: 0.5rem;
}

.border-bottom {
    border-bottom: 0.0625rem solid #eee;
}

.border-top {
    border-top: 0.0625rem solid #eee;
}

.btn-bg-color {
    background-color: var(--btn-bg-color);
}

button:not(.disabled),
button:not([disabled]) {
    &:hover,
    &:focus,
    &:active {
        filter: brightness(110%);
    }
}

.color-ff {
    color: var(--color-ff);
}

i {
    display: inline-block;
}

//购物车全选、共计、nav-title、支付字体色
.pay-bg-color {
    color: var(--color-ff);
}

.all-select {
    color: var(--f55-color);
}

.total-color {
    color: var(--total-color);
}

.title-color {
    color: var(--color-tit);
}

.box-shadow {
    box-shadow: 0 5px 10px 0 var(--box-shadow);
}

.nav-box-shadow {
    box-shadow: 0 0 3px 0 var(--nav-box-shadow);
}

.icon-cha {
    font-size: 15px;
    color: var(--close-color);
    cursor: pointer;
}
.translate-option-item {
    img {
        display: inline-block;
        width: 20px;
        height: 20px;
        flex-shrink: 0;
        vertical-align: middle;
        border-radius: 0.275rem !important;
    }
    .menu-item a,
    .menu-item .menu-link {
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 0;
        flex: 0 0 100%;
        padding: 0.65rem 1rem;
        transition: none;
        outline: none !important;
        color: inherit;
        &:hover,
        &.active,
        &:active,
        &:focus {
            color: var(--focus-on-card-color) !important;
        }
    }
}
.color-red {
    color: var(--focus-on-card-color) !important;
}
body > .dropup {
    border-radius: 0.475rem;
    background-color: #fff;
    box-shadow: 0 0 50px #523f6926;
    z-index: 125;
}

.cursor-pointer {
    cursor: pointer;
}

@media screen and (min-width: 500px) {
    html {
        width: var(--w-i);
        font-size: 16.4px !important;
        margin: 0px auto;
    }
    body {
        width: var(--w-i);
    }
    .nav-footer-part,
    .page-bg,
    .bg-img,
    .head,
    .feedback-page {
        width: var(--w-i);
    }
}

/* You can add global styles to this file, and also import other style files */

// @import './node_modules/ng-zorro-antd-mobile/src/ng-zorro-antd-mobile.min.css';

/* 底部线条 */

.dot-indicator {
    //轮播图的指示点位置
    bottom: 1rem;
}

.am-modal-header {
    //弹框的顶部
    padding: 0px;
}

.am-modal-popup-slide-up {
    //弹框底部距离
    bottom: 3rem;
}

p,
ul,
div {
    margin: 0px;
    padding: 0px;
}

body {
    margin: 0px;
    padding: 0px;
    font-size: 14px;
    line-height: initial;
    background-color: var(--page-bg-color);
}

a {
    text-decoration: none;
}

a:link {
    color: black;
}

/* 未访问的链接 */

a:visited {
    color: black;
}

/* 已访问的链接 */

a:hover {
    color: black;
}

/* 当有鼠标悬停在链接上 */

a:active {
    color: black;
}

/* 被选择的链接 */

li {
    list-style: none;
}

.price-color {
    color: var(--price-color);
}

.left {
    float: left;
}

.right {
    float: right;
}

.clear {
    clear: both;
}

.orange-color {
    color: orange;
}

.color-black {
    color: #303030;
}

.color-gray {
    color: #a5a5a5;
}

.white-color {
    color: white;
}

.web-width {
    width: 100%;
}

.global-bg-color {
    background-color: var(--page-bg-color);
}

// 短横线
.line-min {
    display: inline-block;
    width: 2.67rem;
    height: 1px;
    background-color: #a5a5a5;
    opacity: 0.3;
    vertical-align: middle;
}

.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

//顶部关闭按钮
.header-close {
    text-align: right;
    height: 4rem;
    line-height: 4rem;
}

.head-icon-close,
.disagree i {
    display: inline-block;
    width: 1.6rem;
    height: 1.4rem;
    vertical-align: middle;
    margin-right: 1rem;
    color: #555;
}

//用卡须知
.tem-user-notes {
    font-size: 14px;
    color: #7474c3;
    margin-top: 1rem;
    text-align: center;
}

.tem-user-box {
    display: inline-block;
    height: 1.4rem;
    line-height: 1.4rem;
}

.tem-user-check {
    width: 1rem !important;
    height: 1.4rem;
    margin-right: 5px;
    vertical-align: bottom;
}

.tem-customize-title {
    height: 4rem;
    line-height: 4rem;
    text-align: center;
}

.tem-customize-part {
    height: 32rem;
    font-size: var(--fz-14);
    background-color: var(--page-bg-color);
    color: var(--card-color);
    letter-spacing: 0;
    text-align: justify;
}

.tem-customize-content {
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 25rem;
    line-height: 1.6rem;
    // padding: 0 1rem;
}

//轮播图
.carousel-style {
    display: inline-block;
    width: 23rem;
    height: 20rem;
    text-align: center;
    overflow: hidden;
}

//加载更多的三个点
.more-style {
    font-weight: 500;
    font-size: 20px;
    float: right;
    line-height: 45px;
    color: #999;
    transform: rotate(180deg);
}

//顶部后退按钮
.tem-header-part {
    line-height: 4rem;
    text-align: center;
    background: #000;
}

.tem-header-nav {
    line-height: 4rem;
    position: absolute;
    left: 1rem;
}

.tem-header-img {
    width: 1.6rem;
    height: 1.6rem;
    vertical-align: middle;
}

//顶部titile，后退路径特殊处理
.tem-header-title {
    font-size: var(--fz-16);
    color: #303030;
    font-weight: 700;
    border-bottom: 1px solid #eee;
}

//隐藏组件中的下划线
:host ::ng-deep .mat-form-field-appearance-legacy .mat-form-field-underline {
    height: 0;
}

:host ::ng-deep .mat-form-field-appearance-legacy .mat-form-field-infix {
    padding: 0;
    border-top: 0.59375em solid transparent;
}

:host ::ng-deep .am-list-item:not(:last-child) .am-list-line::after {
    display: none !important;
}

:host ::ng-deep .am-button-ghost {
    color: var(--btn2-bg-color, #ffd000);
    border: 1px solid var(--btn2-bg-color, #ffd000);
}

:host ::ng-deep .am-button-primary {
    background-color: var(--btn2-bg-color, #ffd000);
    border: 1px solid var(--btn2-bg-color, #ffd000);
}

// mat-checkbox选中色
:host ::ng-deep .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: var(--check-select-color);
}

// mat-radio-button选中色
:host ::ng-deep .mat-radio-button.mat-accent .mat-radio-inner-circle {
    background-color: var(--check-select-color);
}

:host ::ng-deep .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: var(--check-select-color);
}

:host ::ng-deep .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple {
    background-color: var(--check-select-color);
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
    background: var(--check-select-color);
}

.mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
    background-color: var(--check-select-color) !important;
}

.am-list-item:not(:last-child) .am-list-line::after {
    display: none !important;
}

.am-list-body::before {
    display: none !important;
}

.am-list-body::after {
    display: none !important;
}

//轮播图指示点默认颜色
.swiper-pagination-bullet {
    background: #999 !important;
    opacity: 0.3;
}

//轮播图指示点颜色
.swiper-pagination-bullet-active {
    background: var(--btn-bg-color) !important;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.toBy {
    position: absolute;
    padding: 0 1rem;
    bottom: 1rem;
    right: 0rem;
    display: block;
    min-width: 5.67rem;
    height: 2.4rem;
    background: var(--toby-bg-color);
    border-radius: 50px;
    text-align: center;
    box-sizing: border-box;
}

.toBy span {
    color: #c22900;
    font-size: var(--fz-12);
    line-height: 2.4rem;
}

.text-color-white {
    color: #fff;
    padding-top: 1rem;
}

@keyframes load {
    0% {
        -webkit-transform: rotate(0deg);
    }
    25% {
        -webkit-transform: rotate(90deg);
    }
    50% {
        -webkit-transform: rotate(180deg);
    }
    75% {
        -webkit-transform: rotate(270deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

.loading {
    animation: load 1s linear infinite;
}

.p-1 {
    padding: 1rem;
}

.m-1 {
    margin: 1rem !important;
}

.b-r {
    border-radius: 8px !important;
}

.b-labr {
    border-radius: 40px;
}

.bg-color-white {
    background-color: #fff !important;
}

/* 开课中 */

.classing {
    border-radius: 0 3px 0 5px;
    padding: 3px 10px;
    background-color: #000;
    color: #fff;
    font-size: 12px;
    position: absolute;
    right: -1px;
    top: -1px;
}

/* 规格样式 */

.guige {
    display: inline-block;
    font-size: var(--fz-12);
    color: #999;
    width: 10rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.guige-detail {
    display: inline-block;
    font-size: var(--fz-12);
    color: #999;
    width: 10rem;
    position: absolute;
    left: 0;
    bottom: 15px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.img-part {
    width: 100%;
    text-align: center;
    position: relative;
    display: table;
    height: 15rem;
}

.img-box {
    display: table-cell;
    vertical-align: middle;
    color: var(--card-color);
    cursor: pointer;
}

.img-box i {
    display: inline-block;
    font-size: 100px;
    color: var(--nothing-color);
}

.card-box {
    background-color: var(--color-ff);
    overflow: hidden;
}

.gift-card-box {
    background-color: var(--color-ff);
    overflow: hidden;
    padding-bottom: 1rem;
}

.card-content {
    font-size: var(--fz-12);
    margin-bottom: 0.27rem;
    padding: 0 0.67rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.subject-part {
    display: inline-block;
    position: relative;
    z-index: 10;
}

.subject-part:last-child {
    margin-right: 0;
}

.search-content {
    opacity: 0.3;
    line-height: 2rem;
    font-size: var(--fz-14);
    color: #000000;
    font-weight: bold;
}

.d-inline-block {
    display: inline-block !important;
}

.d-inline-flex {
    display: inline-flex;
}

.d-flex {
    display: flex;
}

.align-items-center {
    align-items: center;
}

.text-white {
    color: var(--text-fz-color) !important;
}
.w-100px {
    width: 100px;
}
.w-125px {
    width: 125px;
}
.min-w-auto {
    min-width: auto !important;
}
.mr-2 {
    margin-right: 0.5rem;
}
.br-1 {
    border-right: 1px solid var(--bs-border-color);
}
.label-part {
    background-color: var(--color-ff);
    align-items: center;
    font-size: var(--fz-14);
    color: var(--label-color);
    margin-right: 10px;
}

.label-part:last-child {
    margin-right: 0;
}

.label-part:hover {
    background-color: var(--btn2-bg-color);
    cursor: pointer;
    color: var(--label-select-color);
}

.label-part-select {
    background-color: var(--btn-bg-color);
    color: #fff;
}

@media (max-width: 568px) {
    body {
        margin-bottom: 0;
        padding-bottom: 0.4rem;
    }
    .card-box {
        width: 10rem;
        height: 14rem;
    }
    .page-padding {
        padding: 0 1rem;
    }
    .body-color {
        background: var(null) left top no-repeat;
        // margin-bottom: 4rem;
    }
    .global-web-width {
        width: var(--w-i);
    }
    .global-bg-image {
        background: var(--page-bg-img) left top no-repeat;
    }
    .coupon-page {
        width: var(--w-i);
        &:not(.auto-height) {
            height: 32rem;
        }
        background: var(--box-bg-color);
        overflow: scroll;
        font-size: var(--fz-12);
        color: #555555;
    }
    .bread {
        display: none;
    }
    .card-content {
        width: calc(100% - 1.34rem);
    }
    .subject-part {
        margin-right: 1rem;
    }
    .label-part {
        padding: 3px 15px;
    }
    .subject-img {
        width: 100%;
        height: 10rem;
        position: absolute;
        bottom: 0px;
        overflow: hidden;
        border-radius: 0 0 8px 8px;
    }
}

@media (min-width: 569px) {
    .global-bg-color,
    .page-padding,
    .page-bg-color,
    .global-bg-image,
    .global-web-width,
    .web-width {
        position: relative;
        width: min(100%, var(--content-width));
        margin: 0 auto;
        // padding: 0 1rem;
        box-sizing: border-box;
        position: relative;
    }
    .page-bg-color {
        background-color: var(--page-bg-color);
    }
    .subject-page {
        width: min(100%, var(--content-width));
        margin: 1rem auto;
    }
    .label-part {
        padding: 5px 20px;
    }
    .bread {
        color: var(--color-tit);
        font-size: var(--fz-16);
        margin: 1rem;
        margin: 2rem 0;
    }
    .coupon-page {
        width: 400px;
        height: 100%;
        z-index: 99;
        background: var(--box-bg-color);
        overflow: scroll;
        font-size: var(--fz-12);
        color: #555555;
        position: fixed;
        right: 0;
        bottom: 0;
    }
    .card-box {
        width: 220px;
        height: 260px;
    }
    .subject-img {
        width: 100%;
        height: 11rem;
        position: absolute;
        bottom: 0px;
        overflow: hidden;
        margin-bottom: 1rem;
    }
    .subject-part {
        margin-right: max((max(var(--content-width), 100%) - 1100px) / 4, 3px);
    }
}

@media (max-width: 568px) {
    .tem-customize-part {
        width: 100%;
        height: 32rem;
        position: fixed;
        right: 0;
        bottom: 0;
    }
    #somePinchZoom {
        height: 20rem;
        overflow: hidden;
    }
}

@media (min-width: 569px) {
    .tem-customize-part {
        width: 400px;
        height: 100%;
        position: fixed;
        right: 0;
        top: 0;
    }
    #somePinchZoom {
        height: 25rem;
        overflow: hidden;
    }
}

// var(--content-width)
@media screen and (min-width: 569px) and (max-width: 1175px) {
    .global-bg-color,
    .page-padding,
    .page-bg-color,
    .global-bg-image,
    .global-web-width,
    .web-width {
        width: min(calc(100% - 2rem), var(--content-width));
    }
}

.text-center {
    text-align: center;
}

.p-1 {
    padding: 1rem;
}

// 显示两行超出省略号
:host ::ng-deep .line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /*! autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-wrap;
}

//保持控制图片比例为1:1
div.productImg-width {
    position: relative;
    width: 100%;
    height: 0px;
    padding-top: 100%;
}

.product-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    &:not(.ng-img-failed) {
        background: #ccc;
    }
    background-size: 50%;
    background-position: center;
}

.product-image.ng-img-failed {
    border: 1px solid #ccc;
}

// 订单里的更多弹出层弹出申请开票和退货退款
.am-popover-inner {
    font-size: var(--fz-12);
}

.am-popover .am-popover-item-icon {
    margin-right: 0;
    width: 0;
    height: 0;
}
.am-list-item .am-list-line .am-list-arrow-horizontal {
    cursor: pointer;
}

//
.am-modal-transparent .am-modal-content .am-modal-body {
    padding: 0;
}

input {
    cursor: text;
}

:root {
    --sat: constant(safe-area-inset-top);
    --sat: env(safe-area-inset-top);
    --sar: constant(safe-area-inset-right);
    --sar: env(safe-area-inset-right);
    --sab: constant(safe-area-inset-bottom);
    --sab: env(safe-area-inset-bottom);
    --sal: constant(safe-area-inset-left);
    --sal: env(safe-area-inset-left);
}

.am-modal-body {
    .am-modal-alert-content,
    .am-modal-propmt-content {
        padding: 1rem;
    }
}

.pr-0 {
    padding-right: 0 !important;
}

@keyframes ng-img-loading-animition {
    to {
        opacity: 0.1;
        transform: translate3d(0, -8px, 0);
    }
}

// 图片加载动画的父级盒子
.ng-img-loading .ng-img-loading-wrapper {
    display: flex;
    justify-content: center;
}

.ng-img-loading .ng-img-loading-wrapper > div {
    width: 8px;
    height: 8px;
    background-color: var(--userInfo-color, --btn-bg-color);
    border-radius: 50%;
    animation: ng-img-loading-animition 0.6s infinite alternate;
}

.ng-img-loading .ng-img-loading-wrapper > div:nth-child(2) {
    animation-delay: 0.2s;
}

.ng-img-loading .ng-img-loading-wrapper > div:nth-child(3) {
    animation-delay: 0.4s;
}

// 图片加载动画给img套上的父级盒子
.ng-img-wrapper.ng-img-loading {
    width: 100%;
    height: 100%;
}

.ng-img-failed .ng-img-loading-wrapper {
    background: url("assets/icon/img-blank.svg") center no-repeat;
    background-size: contain;
    margin: 0 1rem;
    width: auto !important;
}

input[type="number"] {
    -moz-appearance: textfield;
}

.am-picker-popup.am-picker-popup-wrap.am-slide-up-enter.am-slide-up-enter-active {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
}

.ps__rail-y {
    display: none !important;
}

.hotsale-ft {
    height: 50px;
    position: relative;
}
.hotsale-end {
    display: none;
    position: absolute;
    text-align: center;
    color: #ccccd0;
    left: 50%;
    width: 84px;
    line-height: 20px;
    font-size: 15px;
    margin-left: -42px;
    font-style: normal;
    top: 20px;
}
.hotsale-end::before {
    left: -70px;
}
.hotsale-end::after {
    right: -70px;
}
.hotsale-end::before,
.hotsale-end::after {
    content: " ";
    position: absolute;
    height: 0;
    line-height: 0;
    border-top: 1px solid #e6e6e6;
    width: 60px;
    top: 50%;
}
.my-scrollbar {
    --scrollbar-border-radius: 7px;
    --scrollbar-padding: 4px;
    --scrollbar-viewport-margin: 0;
    --scrollbar-track-color: transparent;
    --scrollbar-wrapper-color: transparent;
    --scrollbar-thumb-color: rgba(0, 0, 0, 0.2);
    --scrollbar-thumb-hover-color: var(--scrollbar-thumb-color);
    --scrollbar-size: 5px;
    --scrollbar-hover-size: var(--scrollbar-size);
    --scrollbar-thumb-transition: height ease-out 150ms, width ease-out 150ms;
    --scrollbar-track-transition: height ease-out 150ms, width ease-out 150ms;
}

/* 从左侧渐入 */
.left-fade-in {
    /* 定义动画 */
    animation-duration: 0.6s; /* 动画时间 */
    animation-name: fadeInFromLeft; /* 关键帧名字 */
    transform: translate3d(0, 0, 0);
    position: relative;
    z-index: 9999;
}

/* 定义关键帧 */
@keyframes fadeInFromLeft {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0px);
    }
}
/* 从右侧渐入 */
.right-fade-in {
    /* 定义动画 */
    animation-duration: 0.6s; /* 动画时间 */
    animation-name: fadeInFromRight; /* 关键帧名字 */
    transform: translate3d(0, 0, 0);
    position: relative;
    z-index: 9999;
}

/* 定义关键帧 */
@keyframes fadeInFromRight {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0px);
    }
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-thumb {
    border-radius: 1em;
    background-color: #3232324d;
}

::-webkit-scrollbar-track {
    border-radius: 1em;
    background-color: #3232321a;
}

body > .dropdown {
    box-shadow: 0 15px 50px #523f6926;
}

.row > * {
    padding-right: calc(var(--bs-gutter-x) * 0.3);
    padding-left: calc(var(--bs-gutter-x) * 0.3);
    margin-top: var(--bs-gutter-y);
    position: relative;
    box-sizing: border-box;
}

input:-internal-autofill-selected {
    background-color: transparent !important;
    background-image: none !important;
}

input:-webkit-autofill {
    box-shadow: 0 0 0 1000px transparent inset !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-transition-delay: 99999s;
    -webkit-transition: color 99999s ease-out, background-color 99999s ease-out;
}

.line-part {
    overflow: hidden;
}

.form-control {
    max-height: 100%;
}

.input-group-text {
    padding: 0 0.75rem;
    height: 100%;
    box-sizing: border-box;
}

.am-tabs-default-bar-underline {
    border: 1px solid var(--btn-bg-color);
}

.text-overflow-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ql-snow * {
    word-wrap: break-word;
    -webkit-text-size-adjust: none;
    font-family: Microsoft Yahei, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, PingFang SC, Helvetica Neue,
        Noto Sans, Noto Sans CJK SC, Arial, Hiragino Sans GB, sans-serif;
    font-size: 14px;
}

.ql-container {
    font-family: Microsoft Yahei, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, PingFang SC, Helvetica Neue,
        Noto Sans, Noto Sans CJK SC, Arial, Hiragino Sans GB, sans-serif;
    font-size: 14px;
}

iframe,
embed {
    max-width: 100%;
    max-height: 100%;
    transform: translate3d(0, 0, 0);
}

.badge.badge-secondary {
    background-color: #ddd;
}

ngb-modal-window.modal {
    z-index: 1056;
}
